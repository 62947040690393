const STRINGS = {
    HOME: "home",
    LATEST_NEWS: "Latest News",
    SUBSCRIBE: "Join Our FREE Mailing List",
    SUBSCRIBE_NOW: "<span style='font-weight: 700;'>FREE</span> Newsletter",
    ADDRESS: "Address: 43 Manchester Street,<br/>London, England,<br/>W1U 7LP",
    ADDRESS_STRING: "43 Manchester Street, London, England, W1U 7LP",
    REG_NO: "12814987",
    TAX_ID: "15067 24673",
    PHONE: "0208 102 0709",
    SUBSCRIPTION_TAG: "PENSION_TIMES"
}

Object.freeze(STRINGS);

export {STRINGS}
