const CONST = {
    CATEGORY_CHUNK_SIZE: 5,
    AD_REFRESH_TIME: 120000,
    SCRIPT_LOADING_TIME_WAIT: 1200
}


Object.freeze(CONST);

export default CONST;
